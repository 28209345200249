export const meta: BlockMeta = {
  title: "Video Header Holding",
  tags: ["root"],
  icon: "format-video",
}

import { RIGHTDOWNARROW } from "@components/atoms/Svg"
import { defineBlock, EditableText, useInlineEditableValue } from "eddev/blocks"
import { useIsSSR } from "eddev/routing"
import { motion } from "framer-motion"
import { decode } from "html-entities"
import { useEffect, useState } from "react"
import ReactPlayer from "react-player"

export default defineBlock("content/video-header", (props) => {
  const isSSR = useIsSSR()
  const matches = env.client && isSSR

  const [introText, setIntroText] = useInlineEditableValue("text", "")
  const [pageLoaded, setPageLoaded] = useState(false)

  const splitTextWords = introText.split(" ").map((char, i) => {
    return char.split("").map((word, i) => {
      return word
    })
  })

  useEffect(() => {
    setTimeout(() => {
      setPageLoaded(true)
    }, 1000)
  }, [])

  const NewText = () => {
    return (
      <p className="type-body-m uppercase text-white mb-4 inline">
        {splitTextWords.map((word, i) => {
          const delay = i + 0.1 * 0.005

          const w = word.join("") === "&amp;" ? ["&"] : word

          return (
            <span className="word type-body-m  uppercase inline-flex mr-2" key={`word-${i}`}>
              {w.map((char, i) => {
                return (
                  <motion.span
                    initial={{
                      opacity: 0,
                    }}
                    animate={
                      pageLoaded
                        ? {
                            opacity: 1,
                            transition: {
                              delay: delay * 0.05,
                              duration: 0,
                            },
                          }
                        : {
                            opacity: 0,
                          }
                    }
                    className="char type-body-m  uppercase inline-flex"
                    key={`char-${i}`}
                  >
                    {decode(char, { level: "html5" })}
                  </motion.span>
                )
              })}
            </span>
          )
        })}
      </p>
    )
  }

  return (
    <div className={`borders--vertical fixed inset-0 z-60 bg-black`}>
      {matches ? (
        <div></div>
      ) : (
        <div className="w-full h-full absolute inset-0">
          <ReactPlayer
            url={props.videoFile?.mediaItemUrl!}
            playing={true}
            loop={true}
            muted={true}
            playsinline={true}
            width="100%"
            height="100%"
            className={`${props.videoFileMobile?.mediaItemUrl ? "hidden lg:block" : "block"} absolute inset-0 object-cover object-center video-cover scale-125`}
          />

          {props.videoFileMobile?.mediaItemUrl && (
            <ReactPlayer
              playsinline={true}
              url={props.videoFileMobile?.mediaItemUrl!}
              playing={true}
              loop={true}
              muted={true}
              width="100%"
              height="100%"
              className="absolute inset-0 object-cover object-center lg:hidden video-cover"
            />
          )}
        </div>
      )}

      <div className="grid-auto absolute inset-0 py-vwFraction2  lg:py-vwFraction  w-full borders--horizontal z-60">
        <div className="col-span-10 col-start-2 flex flex-col relative h-full justify-between px-6 py-[30px]">
          <div className="flex flex-col w-full max-w-[1060px] relative">
            <RIGHTDOWNARROW cx="w-[14px] h-[21px] absolute top-0 -left-2 -translate-x-full -translate-y-4 lg:-translate-y-2 text-primary" />

            <EditableText
              store="eyebrow"
              as={"span"}
              placeholder="Eyebrow goes here"
              className="uppercase type-body-m text-primary mb-4"
            />

            {env.admin ? (
              <EditableText store="text" placeholder="Text goes here" className="type-body-m uppercase text-white" />
            ) : (
              <NewText />
            )}
          </div>
          <div className="w-full flex flex-col lg:flex-row lg:justify-between wysiwyg pb-1 white text-white">
            <EditableText
              store="title"
              placeholder="Title goes here"
              as="p"
              className="py-3 text-white text-left lg:text-center type-body-m !uppercase [&>strong]:text-primary mb-7 lg:mb-0"
            />
            {props.subscribeButton && (
              <a
                href={props.subscribeButton.url!}
                className="w-fit pb-4 lg:pb-0"
                target={props.subscribeButton.target!}
              >
                <button className="border-x-2 border-white border-y-transparent text-white type-body-m uppercase px-5 py-3 focus:outline-none focus:border-white focus:ring-0 focus:ring-transparent focus:ring-opacity-0 transition-all hover:text-primary hover:border-primary">
                  {props.subscribeButton.title}
                </button>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
})
